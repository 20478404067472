.about-me {
  padding: 2rem 2rem 1rem 2rem;
  background: #fff;
}

.about-me > h1 {
  margin-left: 0%;
  font-size: 50px;
}

.about-me__container {
  display: flex;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.about-me__wrapper {
  position: relative;
  margin: 10px 0px 10px;
}

.about-me-writing {
  color: #252e48;
  font-size: 18px;
  line-height: 28px;
}

.about-me-writing > p {
  margin-left: 30%;
  margin-top: -35%;
  text-align: left;
}

.about-me-writing > img {
  height: 400px;
  width: 350px;
  margin-left: -10%;
  border-radius: 50%;
}

@media screen and (max-width: 960px) {
  .about-me-writing > p {
    margin-left: 40%;
    margin-top: -50%;
  }

  .about-me-writing > img {
    height: 400px;
    width: 350px;
    margin-left: -8%;
  }

  .about-me__wrapper {
    position: relative;
    margin: 10px 0;
  }

  .spacing {
    display: none;
  }
}

@media screen and (max-width: 517px) {
  .about-me-writing > img {
    height: 400px;
    width: 350px;
    margin-left: 14px;
  }

  .about-me-writing > p {
    margin-left: 0%;
    margin-top: 3%;
  }
}
