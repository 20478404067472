.experience-intro {
  padding: 1rem;
  text-align: center;
}

.experience-intro__bio {
  font-size: 32px;
  text-align: center;
}

.experience-intro__bio-experience > h2 {
  font-size: 35px;
  text-align: left;
  margin: 0 0 5px 0;
  color: black;
}

.experience-intro__bio-experience > h5 {
  font-size: 25px;
  text-align: left;
  color: black;
}

.experience-intro__bio-experience > p {
  font-size: 20px;
  color: #252e48;
  text-align: left;
}

.experience-intro__bio-education > h2 {
  font-size: 35px;
  text-align: left;
  margin: 0 0 5px 0;
  color: black;
}

.experience-intro__bio-education > p {
  font-size: 20px;
  color: #252e48;
  text-align: left;
}

.experience-intro__bio-interests {
  text-align: left;
}

.experience-intro__bio-interests > h2 {
  font-size: 35px;
  margin: 0 0 5px 0;
  color: black;
}

.experience-intro__bio-interests > p {
  font-size: 20px;
  text-align: left;
  color: #252e48;
}

.experience-intro__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.experience-intro__wrapper {
  position: relative;
  margin: 10px 0 10px;
}

.experience-intro__content {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

a {
  color: #242424;
  text-align: center;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.btn {
  text-align: center;
}
