.projects-intro {
  padding: 0rem;
  background: #fff;
}

.projects-intro-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.projects-intro__wrapper {
  position: relative;
  margin: 10px 0 10px;
}

.projects-intro__writing {
  text-align: center;
  color: #242424;
  font-size: 18px;
  line-height: 24px;
}
